import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export const TermsAndCondition = () => {
    return (
        <>
            <Box sx={{ height: '25vh', backgroundColor: '#f2f2f2f2', }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Stack>
                    <Typography sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "25px", md: "30px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        textAlign: "center",
                    }}>
                        Terms of Use
                    </Typography>
                </Stack>
            </Box >



            <Box>
                <Stack sx={{ p:{xs:5, md:15} }}>

                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15PX' }}>
                        These Terms of Use (the “Terms'') are entered into by and between you
                        and Digitrii Solutions Private Limited.  These Terms govern your use of (1)
                        the Digitrii Solutions Private Limited websites, which are comprised of various
                        URLs and web pages operated by Digitrii Solutions Private Limited, including
                        without limitation onecommunn .com (collectively, the “Site”), and (2) any other services, products,
                        software and applications provided by Digitrii Solutions Private Limited, and any other
                        services accessible using a mobile device (collectively, the “Services”). By using the Site
                        and the Services, you acknowledge that you have read and understood all of the Terms, and
                        you agree to be bound by these Terms. You acknowledge and agree that the provisions,
                        disclosures and disclaimers set forth in these Terms are fair and reasonable and
                        your agreement to follow and be bound by them is not the result of fraud, duress
                        or undue influence exercised upon you by any person or entity.
                        IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SITE OR THE SERVICES.
                    </Typography>

                    <br></br>

                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        One commun reserves the right to change the Terms under which the Site and
                        the Services are offered in its sole and absolute discretion at any time without notice.
                        It is your responsibility to review these Terms for any changes each time that you use the
                        Site or the Services. All changes to the Terms are effective from the date posted. Unless otherwise
                        amended these Terms shall apply to any modified version or upgrade of the Site or the Services.
                    </Typography>


                    <br></br>

                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION
                        REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS
                        LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW
                        DISPUTES WILL BE RESOLVED. PLEASE NOTE THAT digitrii solutions private limited
                        DOES NOT PROVIDE WARRANTIES FOR THE SITE OR THE SERVICES.
                        THESE TERMS ALSO LIMIT [...]’S LIABILITY, AS FURTHER SET FORTH IN SECTIONS 5 AND 6. PLEASE READ THEM CAREFULLY.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >1. Description of the Services</Typography>
                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        [...] enables users to submit search queries or questions by accessing
                        the Site or the Services via the Internet browser of the user’s choice
                        and/or by using a mobile device and service. Users are responsible for
                        obtaining access to the Site and the Services, and that access may involve
                        third party fees, costs, or expenses (such as those incurred in association
                        with Internet access, wireless telephone access, text messaging, or other
                        access and communication services fees). [...] may provide the Site and/or
                        the Services with or without the assistance of a human assistant (a “Guide”).
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        Standard telecommunication rates, text messaging rates, and other rates may als
                        o apply depending on your telecommunications provider and service plan.
                        Please check your plan or call your telecommunications provider to be
                        sure of all costs associated with the use of your wireless device.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        You can gain access to the Services available using a mobile
                        device by entering your telephone number and wireless carrier
                        information via the web form found at http://www.onecommunn.com,
                        by texting a search query or question to the short code […] for more information.
                        Text search queries or questions must be no more than the length of a single text message
                        (i.e., 160 characters). Your access to the Services may be restricted or limited by
                        One Communn or by your telecommunications provider and service plan.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        The Site and the Services may contain links to third
                        party web sites or programs that are not controlled by One Communn.
                        One Communn is not responsible for the content, offers or privacy policies
                        of such sites and programs, including, without limitation, your redemption
                        of such offers or a merchant’s refusal to honor any offer. Your dealings with
                        third parties are solely between you and the applicable third party.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        [...] enables users to submit search queries or questions by accessing
                        the Site or the Services via the Internet browser of the user’s choice
                        and/or by using a mobile device and service. Users are responsible for
                        obtaining access to the Site and the Services, and that access may involve
                        third party fees, costs, or expenses (such as those incurred in association
                        with Internet access, wireless telephone access, text messaging, or other
                        access and communication services fees). [...] may provide the Site and/or
                        the Services with or without the assistance of a human assistant (a “Guide”).
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        2. Limited use of the Services
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        One Communn reserves the right to restrict the use of certain features
                        of the Site and the Services. Although One Communn hopes you will
                        find the information that you obtain via the Site or the Services to be useful,
                        One Communn does not guarantee that the information will (a) be accurate,
                        complete or useful in any way whatsoever, (b) include all of the information
                        available relating to a topic or query, or satisfactorily address your query.
                        Information provided by the Site or the Services is not and cannot be used as
                        a substitute for the advice of a qualified doctor, psychiatrist, psychologist,
                        accountant, attorney, financial analyst, engineers, or other professional.
                        Accordingly, if you need advice regarding a regulated or specialized field
                        or industry, you must seek a professional who is licensed, qualified or
                        knowledgeable in that area. The Guides who assist One Communn in providing
                        the Site and the Services are independent contractors who operate under a
                        separate agreement with One Communn. One Communn is not in any manner legally
                        responsible for any content provided, or actions taken, by Guides, including any
                        content or actions which may be taken as offensive, defamatory, harassing, fraudulent,
                        inaccurate, or otherwise objectionable. You may report any concerns to One Communn by
                        use of the Contact link provided herein, as well as the Contact link located on the
                        One Communn homepage.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        Your use of the Site and the Services, including any communication by,
                        with or to One Communn, may be recorded. By using the Site and the Services,
                        you consent to such recording.
                    </Typography>
                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        The Site and the Services are provided on an “as-is” basis and should not be relied upon
                        for any purposes whatsoever. No commercial use or use that could be construed as commercial
                        use is permitted without the express written consent of One Communn. Any rights, not expressly
                        granted under these Terms are reserved by One Communn. One Communn grants you a personal,
                        nonexclusive ability to use the Site and the Services only as authorized in these Terms.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        You may use the Site and the Services only for legal and appropriate uses.
                        One Communn reserves the right to make changes to the Site and the Services
                        at any time and without notice. Your access to the Site and the Services is
                        completely at the discretion of One Communn, and your access to the Site and
                        the Services may be blocked, suspended, or terminated without prior notice at
                        any time for any reason or for no reason, including, without limitation, for any violation of the following rules:
                    </Typography>
                    <br></br>
                    <List sx={{ fontFamily: "Montserrat" }}>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You must comply with all state, federal, and/or international laws, rules, policies and/or licenses governing communications while using the Site and the Services, and with all applicable copyright, trademark, or other intellectual property rights laws." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not submit queries that are unlawful, defamatory, harassing, abusive, fraudulent, obscene, or are otherwise objectionable." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not submit queries that are designed to elicit responses that relate to illegal activity or that infringe upon another party’s intellectual property rights." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not allow anyone else to use the Site or the Services who does not agree to these Terms." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not submit queries to the Site or any of the Services using automated means without the prior express written consent of One Communn." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not sell, use, transfer, reproduce, modify, edit, publish, perform, display, post, transmit or distribute the transcript of any communication that you may have in connection with your use of the Site or the Services without the written permission of One Communn." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not upload, post, email, transmit or otherwise make available any content which infringes any trademark, patent, copyright or trade secret or other proprietary right of any party, unless you are the owner of the rights or have the permission of the owner to post such content." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not disrupt the operation of the Site or the Services or intimidate, harass, or intentionally offend the Guides." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not use the Site or the Services for commercial purposes." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not interfere or attempt to interfere with the Site or the Services by use of any program, script, command, device, software, routine, or otherwise. This includes, but is not limited to attempts to overload the Site or the Services or to excessively burden One Communn’s resources." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not use the Site or the Services if One Communn finds such use is no longer commercially feasible." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not attempt to gain unauthorized access to any portion or feature of the Site or the Services, by hacking, password “mining” or any other illegitimate means." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not create user accounts by automated means or under false or fraudulent pretenses." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not submit content that falsely expresses or implies that such content is sponsored or endorsed by One Communn." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not embed any page of the Site in “frames” running from other websites." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not mirror the Site or the Services on any other website or server." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not remove any copyright, trademark or other proprietary rights notices contained in or on the Site or the Services." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Your access to the Site is restricted to conventional non-accelerated browsers. So-called “web-accelerator” products such as Net Jet, Net Sonic, MSIE Crawler, or Teleport Pro are not permitted." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="You may not transmit any worms, Trojan Horses, viruses, defects, or any items of a destructive nature." />
                        </ListItem>
                    </List>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        When you visit the Site, use the Services, or contact One Communn by
                        other means, you consent to receive communications from One Communn.
                        One Communn may contact you electronically or by other means One
                        Communn chooses. You agree that all agreements, notices, disclosures,
                        and other communications that One Communn provides to you via text
                        message, email, or other form of electronic communication shall satisfy
                        any legal requirement that such communications be in writing.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        By accepting these Terms and submitting a query or request to One Communn via voice,
                        text, or internet, you expressly agree that we may send a response to your queries
                        to your wireless device or email via an SMS text message or email or WAP Push
                        Message or MMS message. You are granting express permission to receive our
                        response even if you are registered on a Do-Not-Call registry (NDNC).
                        You expressly agree that, as part of the Services provided by One Communn,
                        you may receive promotional communications, alerts, and third party advertising
                        materials from One Communn via text message. You may stop receiving such text
                        messages by emailing your request to opt-out, along with your wireless telephone
                        number, to support@onecommunn.com, or following any opt-out instructions in the relevant text message.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        You may cancel your access to the Services accessible
                        using a mobile device by sending an email to [...] at support@onecommunn.com.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        Unintended parties may receive communications which are directed
                        to One Communn by you or which are directed to you by One Communn.
                        One Communn is not responsible for any communications which are lost,
                        intercepted or misdirected. Your telephone number and communications
                        may be intercepted by third parties without your or One Communn’s
                        knowledge or permission. One Communn is not responsible for information sent
                        using text, voice, or other communication services.

                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        In connection with your use of the Site or the Services, you may be required
                        to register with One Communn by providing certain personally identifiable
                        information about yourself, including, but not limited to, your name, age,
                        gender, zip code, email address and mobile telephone number. In consideration
                        for One Communn granting you access to the Site or the Services
                        , you hereby expressly agree to provide true, accurate, current
                        and complete information about yourself as requested and as necessary
                        for One Communn’s provision of the Services, subject to all applicable
                        laws. If you provide any information that is untrue, inaccurate, not
                        current or incomplete, or if One Communn has reasonable grounds to
                        suspect that the information that you have provided is untrue,
                        inaccurate, not current or incomplete, One Communn  has the
                        absolute right to suspend or terminate your use of the
                        Site or the Services at any time and refuse you any
                        and all current or future use of the Site or the
                        Services. You may receive a registration
                        key/password in order to access the Site
                        or the Services. You are solely responsible
                        for maintaining the confidentiality of the
                        registration key/password, and are solely
                        responsible for all activities that occur
                        under your registration key/password,
                        and One Communn may rely upon the authority
                        of anyone using your account or password.
                        In the event that the confidentiality of your account or password is compromised in
                        any manner, you should notify One Communn immediately. One Communn reserves the
                        right to take any and all action it deems necessary to ensure the integrity of t
                        he Site, the Services and your account, including but not limited to terminating your account, changing your password, or requesting additional information in order to access your account. In no event and under no circumstances will One Communn be held liable to you for any liability or damages resulting or arising from any action or inaction of One Communn under this provision, any compromise of the confidentiality of your account or password, and any unauthorized access to your account or use of your password. Any unauthorized use of the Site or the Services will terminate any permission or license granted under these Terms and may violate applicable laws and statutes including trademark laws, copyright laws, and communications regulations and statues. All violators will be prosecuted to the fullest extent of the law.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        Your use of the Site and the Services are governed by the One Communn Privacy Policy.
                        By using the Site and the Services you agree and
                        acknowledge that your use of the Site and the Services are also subject to the One Communn Privacy Policy.

                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        You agree not to modify, adapt, translate, prepare derivative works from, decompile,
                        reverse engineer, disassemble, or otherwise attempt to derive source code from the Site or the Services.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        3. Intellectual Property
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        One Communn is the owner or licensee of all copyrights in and to the content,
                        designs, information, text, software, HTML code, data, images and materials displayed on,
                        or used in connection with, the Site and the Services (“Content”). You expressly assign to One
                        Communn any intellectual property rights that may result from your use of the Site or the Services,
                        including but not limited to any copyright interest that you obtain in the work product that results
                        from any communication that may take place between you and One Communn through your use of the Site or
                        the Services (“Work Product”). By making any Content or Work Product available through the Site or the Services,
                        One Communn does not grant any license or other authorization to copy, use, reproduce, perform, display, or
                        distribute its copyrightable material, or other intellectual property, except as provided herein.
                        For purpose of clarification but not limitation, you may not modify, copy, distribute, transmit, display,
                        reproduce, publish, license, create derivative works from, transfer, or sell any Content and/or Work Product,
                        unless you first obtain the written permission of One Communn. If you make use of any Content and/or Work
                        Product other than as authorized herein, you may be in violation of copyright laws and other laws of India
                        and other countries, as well as applicable state laws.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        Other than Work Product and Content, One Communn does not claim ownership of any other correspondence,
                        materials, and information that you submit to One Communn, or that you post on the Site (“Your Materials”).
                        Nevertheless, you automatically grant to One Communn an irrevocable, perpetual, non-exclusive, fully paid,
                        worldwide license to use, reproduce, copy, adapt, modify, translate, publish, perform, display, distribute,
                        make available, and store Your Materials, and to prepare derivative works based on Your Materials.
                        Additionally, you agree that One Communn may sublicense and/or assign its rights in Your Materials.
                        No compensation will be paid with respect to this use of Your Materials. You also grant One Communn
                        the right to use your name in connection with Your Materials, for testimonial and similar purposes.
                        One Communn is under no obligation to post or use any of Your Materials, is under no obligation
                        to maintain Your Materials and may remove Your Materials at any time in One Communn’s sole discretion.
                        You agree that you shall have no recourse against One Communn for any alleged or actual infringement of
                        any proprietary rights in Your Materials, and that the submission of such materials to One Communn,
                        irrevocably waives any and all “moral rights” in Your Materials including the rights of paternity and
                        integrity. You represent and warrant that you own or otherwise control all rights to Your Materials,
                        and that the content is accurate, will not cause harm to any person or entity, that the use of Your
                        Materials does not violate these Terms, and that you indemnify One Communn for all claims resulting
                        in any way from the use of Your Materials.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        All other products, services, and company names mentioned on the Site are the trademarks
                        of their respective owners. By placing them on the Site or within the Services, One Communn
                        does not grant to you any license or other authorization to copy, reproduce, or use the trademarks
                        or logos, except as provided herein.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        If you believe that any material on the Site infringes on any copyright that you own or control,
                        please follow the procedures outlined in our Notice to Copyright Owners.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        One Communn is the owner, licensee, or assignee of all legal right, title and interest in and to the Site and the Services,
                        including any intellectual property relating to
                        the Site and the Services which may include any registered or unregistered rights or any patent pending technology.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        4. Links
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        The Site and the Services may contain advertisements, promotions,
                        and links to external websites. Your correspondence or business dealings with,
                        or participation in promotions of, other parties found on or through the Site and the
                        Services are solely between you and such parties. This includes payment and delivery of related
                        goods or services, and any other terms, conditions, warranties or representations associated
                        with such dealings. You agree that One Communn is not responsible or liable for any loss or
                        damage incurred as the result of any such dealings. One Communn does not endorse, and One
                        Communn is not liable for any content, products, services, software or other materials
                        available on such other sites, even if a page or pages of the other sites are framed with
                        a page of the Site. One Communn is not responsible for the privacy practices or the content
                        of other websites. For your protection, please refer to the terms of service and privacy
                        policies of the respective websites. You acknowledge, understand and agree that One Communn
                        shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
                        alleged to be caused by or in connection with use of or reliance on any such content, goods
                        or services available on such other websites. One Communn shall not be liable for any errors or
                        delays in the content, goods or services available on such other websites, or for any actions taken or
                        not taken in reliance thereon.
                    </Typography>

                    <br></br>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        5. Disclaimer of Warranties
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND THE SERVICES ARE PROVIDED “AS IS,”
                        “WITH ALL FAULTS,” “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                        ONE COMMUNN DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE SITE OR THE SERVICES
                        WILL BE UNINTERRUPTED, ERROR FREE, OR VIRUS FREE, OR THAT THE SITE OR THE SERVICES WILL MEET
                        YOUR REQUIREMENTS. ANY INFORMATION PROVIDED BY OR OTHERWISE OBTAINED THROUGH THE SITE OR THE SERVICES
                        HAS NOT BEEN VERIFIED, AND NEITHER ONE COMMUNN NOR ANY GUIDE GUARANTEES, REPRESENTS, OR WARRANTS THAT
                        SUCH INFORMATION IS ACCURATE, COMPLETE, RELIABLE, OR OTHERWISE VALID. ONE COMMUNN DOES NOT ENDORSE OR
                        RECOMMEND, AND EXPRESSLY DISCLAIMS LIABILITY FOR, ANY PRODUCT, MANUFACTURER, DISTRIBUTOR, SERVICE OR
                        SERVICE PROVIDER MENTIONED IN ANY QUERY OR RESPONSE.
                    </Typography>



                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        ONE COMMUNN DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY WARRANTY OF TITLE,
                        NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT,
                        OR ANY WARRANTY ARISING FROM A COURSE OF DEALING, TRADE USAGE, OR TRADE PRACTICE.
                        THESE DISCLAIMERS APPLY TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR,
                        OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS,
                        COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF ONE
                        COMMUNN’S RECORDS OR INFORMATION, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR ANY OTHER CAUSE OF ACTION.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        6. Limitation of Liability
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        YOU ACKNOWLEDGE AND AGREE THAT USE OF THE SITE AND THE SERVICES ARE AT YOUR SOLE RISK.
                        YOU ACKNOWLEDGE THAT INFORMATION TRANSMITTED THROUGH THE INTERNET,
                        THE SERVICES OR OTHERWISE, IS NOT SECURE.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        NEITHER ONE COMMUNN , NOR ANY OF ONE COMMUNN’S GUIDES, EMPLOYEES, SUBSIDIARIES, AFFILIATES, AGENTS,
                        REPRESENTATIVES, OR LICENSORS (COLLECTIVELY, “ONE COMMUNN  ASSOCIATES”) SHALL BE LIABLE TO YOU OR
                        ANY THIRD PARTY FOR ANY COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE,
                        OR CONSEQUENTIAL DAMAGES, OR ATTORNEYS’ FEES, OR FOR LOST DATA OR LOST PROFIT, ARISING OUT OF YOUR
                        USE OF THE SITE OR THE SERVICES OR INABILITY TO GAIN ACCESS TO OR USE THE SITE OR THE SERVICES OR
                        OUT OF ANY BREACH OF ANY WARRANTY, EVEN IF ONE COMMUNN OR A ONE COMMUNN ASSOCIATE HAS BEEN ADVISED
                        OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE FORESEEABLE.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        YOUR SOLE RIGHT AND REMEDY WITH RESPECT TO ANY DISPUTE WITH ONE COMMUNN
                        OR ANYONE COMMUNN ASSOCIATE IS TO STOP USING THE SITE OR THE SERVICES.
                        BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                        LIABILITY FOR CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, IN SUCH STATES AND
                        JURISDICTIONS LIABILITY IS LIMITED TO THE GREATEST EXTENT AS PERMITTED BY LAW.
                        IF THE FOREGOING LIMITATIONS ARE UNENFORCEABLE, YOU AGREE THAT ONE COMMUNN’S LIABILITY
                        TO YOU UNDER THESE TERMS SHALL NOT EXCEED […] .
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        THE SITE AND THE SERVICES ARE CONTROLLED, OPERATED AND ADMINISTERED BY ONE COMMUNN FROM ITS OFFICES
                        WITHIN INDIA.ONE COMMUNN MAKES NO WARRANTY OR REPRESENTATION THAT MATERIAL AVAILABLE THROUGH
                        THE SITE OR THE SERVICES IS LEGAL, APPROPRIATE, OR AVAILABLE FOR USE OUTSIDE THE UNITED STATES.
                        IF YOU ACCESS THE SITE OR THE SERVICES FROM A LOCATION OUTSIDE INDIA, YOU ARE RESPONSIBLE FOR
                        COMPLIANCE WITH ALL APPLICABLE LAWS, AND ONE COMMUNN ACCEPTS NO RESPONSIBILITY FOR SUCH ACCESS.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS ONE COMMUNN AND THE ONE COMMUNN ASSOCIATES,
                        FROM AND AGAINST ANY CLAIMS, DAMAGES, COSTS, AND EXPENSES, INCLUDING ALL COSTS OF LEGAL PROCEEDINGS
                        AND ATTORNEYS’ FEES, ARISING IN CONNECTION WITH USE OF THE SITE AND THE SERVICES BY YOU, OR IN
                        CONNECTION WITH A VIOLATION OR BREACH OF THESE TERMS BY YOU, INCLUDING WITHOUT LIMITATION, YOUR
                        VIOLATION OF ANY LAWS GOVERNING COMMUNICATIONS OR INTELLECTUAL PROPERTY.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        YOU ACKNOWLEDGE AND AGREE THAT ANY AND ALL DISCLAIMERS IN THESE TERMS AND THE PROVISIONS OF THESE TERMS
                        REFLECT A FAIR AND REASONABLE ALLOCATION OF RISK BETWEEN ONE COMMUNN AND YOU.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        YOU ACKNOWLEDGE AND AGREE THAT ANY AND ALL DISCLAIMERS IN THESE TERMS AND THE PROVISIONS OF THESE TERMS
                        REFLECT A FAIR AND REASONABLE ALLOCATION OF RISK BETWEEN ONE COMMUNN AND YOU.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        7. Dispute Resolution
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        These Terms and the relationship between you and One Communn shall be governed by the laws of One Communn  ,
                        without regard to any conflict of law or choice of law provisions of any state to the contrary. To the extent
                        you have in any manner violated or threatened to violate One Communn’s intellectual property rights or your
                        obligations with respect to One Communn’s confidential information, One Communn may file an action seeking
                        injunctive or other appropriate relief in any court of competent jurisdiction, and you consent to the personal
                        jurisdiction of and venue in such courts and waive any right to a jury trial in connection with any such action.
                        Without limiting the foregoing, you consent to the personal jurisdiction of and venue in the courts located within One Communn  ,
                        India. in the event of any dispute, and waive any future right to object to this designation of jurisdiction and venue and waive any right to a
                        jury trial in connection with any such action. You agree that any claim or cause of action arising out of or related to use of the Site or the Services
                        or these Terms must be brought within one (1) year after such claim or cause of action accrues or be forever barred.
                        The one-year period begins on the date when the claim first could be filed. If it is not filed in time, then that
                        claim is permanently barred. This applies to you, your successors and permitted assigns.
                    </Typography>

                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '20px', color: '#000000', fontWeight: '600' }} >
                        8. Miscellaneous
                    </Typography>
                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        The failure of One Communn to exercise or enforce any right or provision of these Terms shall not
                        constitute a waiver of such right or provision. If any provision of these Terms is found by a court
                        of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor
                        to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms
                        shall remain in full force and effect. The headings used in these Terms are for convenience only and such headings
                        are not to be used in determining the meaning or interpretation of these Terms. Nothing in these Terms shall be
                        construed to establish a partnership, joint venture, agency, employment, or other business relationship between
                        you and One Communn.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        You shall have no right to assign all or any part of these Terms,
                        and any attempt to do so shall be void. One Communn shall have the right to
                        assign these Terms and to sublicense any and all of its rights under these Terms.
                    </Typography>


                    <br></br>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: '15px' }}>
                        These Terms, including any documents referenced herein, represent the entire understanding
                        between you and One Communn regarding your relationship with One Communn and your use of the
                        Site and the Services. These Terms shall not be construed against any party by reason of their preparation,
                        but shall be resolved by applying the most reasonable interpretation under the circumstances, giving
                        full consideration to the intentions of the parties at the time of contracting. These Terms supersede all previous
                        written or oral agreements between you and One Communn with respect to such subject matter. Where One Communn has
                        provided you with a translation of an English version of these Terms, you agree that the translation is provided for
                        your convenience only and that the English language version of the Terms will govern your relationship with One Communn.
                        If there is any contradiction between what the English language version of the Terms say and what a translation says,
                        the English language version shall take precedence. Notwithstanding any provisions of these Terms, One Communn has available all
                        remedies at law or equity to enforce these Terms.
                    </Typography>


                </Stack>
            </Box>
        </>
    )
}