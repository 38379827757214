import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import background from "../../Assets/Images/background.png";
import background1 from "../../Assets/Images/Home/bg1.webp"
import { useTypewriter } from "react-simple-typewriter";
import { Helmet } from "react-helmet";
import HomeUseCases from "./HomeUsecases";

const Home = () => {
  const [text] = useTypewriter({
    words: [
      "Interaction",
      "Communication",
      "Subscriptions",
      "Payments",
      "CMS",
      "Advanced Analytics",
    ],
    loop: 100,
    typeSpeed: 100,
    deleteSpeed: 50,
  });

  return (
    <>
      <Helmet>
        <title>Home - Onecommunn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>

      <Stack>
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "90vh", md: "100vh" },
            pt: 5,
            pb: 10,
            mt:-15,

          }}
        >
          <Grid container sx={{ ml: 3, mr: 3 }}>
            <Stack>
              <Grid
                container
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
                display={"flex"}
                spacing={5}
              >
                <Grid item xs={12} md={5}>
                  <Stack sx={{ pt: 6, pb: 6 }}>
                    <Typography
                      sx={{
                        color: "#2952A2",
                        fontFamily: "Montserrat",
                        fontSize: { xs: "22px", md: "30px" },
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      "Automate Community Management with AI-Powered <br></br>
                      <Box component="span"
                        sx={{
                          fontSize: { xs: "25px", md: "35px" }, color: "#3B9B7F",
                          fontWeight: "bold", fontFamily: "Montserrat",
                          textTransform: "capitalize"
                        }}>
                        {text}
                      </Box>

                      &nbsp; Effortlessly"
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",
                        textAlign: { xs: "center", md: "justify" },
                        fontSize: "15px",
                        lineHeight: "30px",
                        mt: 2,
                        display: { xs: "none", md: "block" },
                      }}
                    >
                      Our AI-powered platform enhances community management with
                      tools for user data management, interaction,
                      communication, subscription and payment processing,
                      content management, and advanced analytics
                    </Typography>
                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      mt={3}
                      sx={{justifyContent:{xs:'center', md:'flex-start'}}}
                      
                    >
                      <Box component={"a"} href="https://play.google.com/store/apps/details?id=com.communn_mobile_app" sx={{ width: "35%", pr: 2, display: 'flex', alignItems: 'center' }}> 
                      <Box
                        component={"img"}
                        src={require("../../Assets/Images/Home/Playstore.png")}
                        alt=""
                        sx={{ width: "100%" }}
                        
                      />
                      </Box>
                      
                      <Box
                        component={"img"}
                        src={require("../../Assets/Images/Home/Appstore.png")}
                        alt=""
                        sx={{ width: "35%",pl:2 }}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Box
                    component={"img"}
                    src={require("../../Assets/Images/Home/HomeMobile.png")}
                    alt=""
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Box>

        <Stack mt={-15}>
          <Box
            component={"img"}
            src={require("../../Assets/Images/Home/Vector 26.png")}
            alt=""
            sx={{ width: "100%" }}
          />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={2}
              sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
            >
              <Grid item xs={12} md={5}>
                <Box
                  component={"img"}
                  src={require("../../Assets/Images/Home/1.webp")}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack
                  sx={{
                    ml: { xs: 1, md: 10 },
                    pt: 6,
                    pb: 6,
                    backgroundColor: "#ffffff",
                    borderRadius: "15px 0px 0px 15px",
                  }}
                >
                  <Paper sx={{ p: { xs: 2, md: 5 }, boxShadow: "none" }}>
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "20px", md: "30px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      Its for Anyone
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",
                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      Eager to tap into community building potential? Communn is
                      the platform for you, whether you have a community or not.
                      Share expertise, create connections, earn revenue, and
                      manage payments seamlessly. Ready to start something
                      great? Join Communn.
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Stack>
          <Typography
            sx={{
              color: "#2A53A2",
              fontSize: { xs: "20px", md: "30px" },
              fontFamily: "Montserrat",
              fontWeight: "bold",
              textAlign: { xs: "left", md: "center" },
              mb: 5,
              ml: { xs: 3, md: 0 },
            }}
          >
            Use Cases
          </Typography>
        </Stack>

        {/* UsesCases  */}

        <HomeUseCases />



        <Box
          sx={{
            // backgroundImage: `url(${Dashboard})`,
            // backgroundSize: "contain",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
            pt: 2,
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={2}
            >
              <Grid item xs={12} md={5}>
                <Stack
                  sx={{
                    ml: { xs: 0, md: 5 },
                    pt: 5,
                    pb: 6,
                    borderRadius: "15px 0px 0px 15px",
                  }}
                >
                  <Paper
                    sx={{
                      p: { xs: 2, md: 5 },
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "20px", md: "30px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      Effortlessly Manage your Community
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      Say goodbye to complicated management tasks. With Communn,
                      you can effortlessly and efficiently handle tasks such as
                      organizing content, managing members, managing payments
                      and overseeing community operations without encountering
                      unnecessary challenges or complications all through our
                      user-friendly tools.
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  component={"img"}
                  src={require("../../Assets/Images/Home/5.webp")}
                  alt=""
                  sx={{
                    width: "100%",
                    ml: { xs: 0, md: 16 },
                    overflow: "hidden",
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
            pt: 2,
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={2}
              sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
            >
              <Grid item xs={12} md={5}>
                <Box
                  component={"img"}
                  src={require("../../Assets/Images/Home/7.png")}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack
                  sx={{
                    ml: { xs: 0, md: 5 },
                    pt: 5,
                    pb: 6,
                    borderRadius: "15px 0px 0px 15px",
                  }}
                >
                  <Paper
                    sx={{
                      p: { xs: 2, md: 5 },
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                        fontSize: { xs: "20px", md: "30px" },
                      }}
                    >
                      Elevate with Subscriptions creation
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      Discover a new world of possibilities with subscriptions.
                      Communn empowers you to transform your audience into a
                      vibrant and loyal community. Generate recurring revenue,
                      foster meaningful connections, and create a sustainable
                      ecosystem that benefits both you and your members.
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Box
          sx={{
            backgroundImage: `url(${background1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "150vh" },
            pt: 2,
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={2}
            >
              <Grid item xs={12} md={5}>
                <Stack
                  sx={{
                    ml: { xs: 0, md: 5 },
                    pt: 5,
                    pb: 6,
                    borderRadius: "15px 0px 0px 15px",
                  }}
                >
                  <Paper
                    sx={{
                      p: { xs: 2, md: 5 },
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "20px", md: "30px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      Communicate and Interact with Ease
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      Experience seamless communication and transform the way
                      you engage with your community. Communn provides powerful
                      tools and intuitive communication channels to build strong
                      connections. Foster discussions, inspire interactions, and
                      keep your members COMING BACK FOR MORE.
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  component={"img"}
                  src={require("../../Assets/Images/Home/8.png")}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default Home;
