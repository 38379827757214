import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, makeStyles, IconButton, Menu, List, ListItem, ListItemText } from '@mui/material';
import background from "../../Assets/Images/background.png";
import MenuIcon from '@mui/icons-material/Menu';
import MobileSideBar from './MobileNav/MobileSidebar';

interface Page {
  label: string;
  value: string;
  dropdown: boolean;
  submenu?: {
    label: string;
    value: string;
    navigate: string;
  }[];
  navigate: string;
}

const pages: Page[] = [
    { label: 'Home', value: 'Home', dropdown: false, navigate: '/home' },
  {
    label: 'I Can',
    value: 'I Can',
    dropdown: true,
    submenu: [
      { label: 'Navigate Dashboard', value: ' Dashboard', navigate: '/dashboard' },
      { label: 'Manage Members', value: 'Members', navigate: '/members' },
      { label: 'Manage Posts', value: 'Posts', navigate: '/post' },
      { label: 'Manage Payments', value: 'Payments', navigate: '/payments' },
      { label: 'Manage Subscriptions', value: 'Subscriptions', navigate: '/subscription' },
      { label: 'Manage Communities', value: 'Community', navigate: '/communities' },
    
     
    ],
    navigate: '/features1',
  },
  {
    label: 'I am',
    value: 'I am',
    dropdown: true,
    submenu: [
      { label: 'An Entrepreneur', value: 'An Entrepreneur', navigate: '/business' },
      { label: 'A Homemaker', value: 'A Homemaker', navigate: '/entrepreneur' },
      { label: 'A Teacher', value: 'A Teacher', navigate: '/brand' },
      { label: 'A Wellness/Health Instructor', value: 'A Wellness/Health Instructor', navigate: '/wellness' },
    ],
    navigate: '',
  },
  { label: 'Pricing', value: 'Pricing', dropdown: false, navigate: '/pricing' },
  { label: 'About Us', value: 'About Us', dropdown: false, navigate: '/about' },
  { label: 'Contact Us', value: 'Contact Us', dropdown: false, navigate: '/contacts' },
  
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [selectedPages, setSelectedPages] = React.useState<{ [key: string]: string | null }>({});
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, pageValue: string) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageChange = (value: string, navigateTo: string, dropdownKey: string) => {
    if (value) {
      // Check if the clicked item is a submenu item
      const submenuItem = pages
        .find((page) => page.value === dropdownKey)
        ?.submenu?.find((item) => item.value === value);

      if (submenuItem) {
        // It's a submenu item, set the selected page for the submenu
        setSelectedPages((prev) => ({ ...prev, [dropdownKey]: submenuItem.value }));
        navigate(submenuItem.navigate);
      } else {
        // It's a top-level item, navigate to the specified route
        setSelectedPages((prev) => ({ ...prev, [dropdownKey]: value }));
        navigate(navigateTo);
      }
    }

    handleCloseNavMenu();
  };
  const handleGetStartedClick = () => {
    
    window.location.href = 'https://admin.onecommunn.com/';
  };
  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          p: 1,
          // backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* ... (your logo code) */}
            {/* <Box
              component={"img"}
              src={require("../../Assets/Images/Logo.png")}
              alt=""
              sx={{ width: "15%", display: { xs: 'none', sm: 'none', md: 'block' } }}
            />
            <Box
              component={"img"}
              src={require("../../Assets/Images/Logo.png")}
              alt=""
              sx={{ width: "65%", display: { xs: 'block', sm: 'block', md: 'none' } }}
            /> */}
            <Box component={"a"} href="/" sx={{ width: "15%",}}> 
            <Box
            component={"img"}
            src={require("../../Assets/Images/Logo.png")}
            alt=""
            sx={{
              width: "100%",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          </Box>
          <Box
            component={"img"}
            src={require("../../Assets/Images/Logo.png")}
            alt=""
            sx={{
              width: "50%",
              display: { xs: "block", sm: "block", md: "none" },
            }}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <MobileSideBar />
          </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'start' } }}>
              {pages.map((page) => {
                if (page.dropdown) {
                  const dropdownKey = page.value;
                  const selectedPage = selectedPages[dropdownKey];
                  const menuPaperProps = {
                    sx: {
                      marginLeft: page.label === 'I Can' ? '56px' : '80px',
                    },
                  };
                  return (
                    <FormControl key={page.value} sx={{ mt: 2.3, m: 2, minWidth: 100 }}>
                      <Select
                        id={`select-${dropdownKey}`}
                        // value={selectedPages[dropdownKey] || ''}
                        value={page?.value}
                        displayEmpty
                        disableUnderline
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => handlePageChange(e.target.value as string, page.navigate, dropdownKey)}
                        variant="standard"
                        sx={{ '& .MuiInputBase-root': { paddingTop: '16px' } }}
                      
                        MenuProps={{
                          PaperProps: menuPaperProps,
                        }}
                      >
                        <MenuItem value={page.value} disabled={!selectedPage} sx={{ display: 'none' }}>
                          {selectedPage ? (
                            <Typography sx={{ fontFamily: 'Montserrat', color: '#3C3C3C', fontWeight: 'bold' }}>
                              {page.label}
                            </Typography>
                          ) : (
                            <Typography sx={{ fontFamily: 'Montserrat', color: '#3C3C3C', fontWeight: 'bold' }}>
                              {page.label}
                            </Typography>
                          )}
                        </MenuItem>
                        {page.submenu &&
                          page.submenu.map((submenuItem) => (
                            <MenuItem key={submenuItem.value} value={submenuItem.value}>
                              <Typography sx={{ fontFamily: 'Montserrat', color: '#3C3C3C', fontWeight: '500' }}>
                                {submenuItem.label}
                              </Typography>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  );
                } else {
                  return (
                    <Link to={page.navigate} key={page.value} style={{ textDecoration: 'none' }}>
                      <Button
                        key={page.value}
                        onClick={() => handlePageChange(page.value, page.navigate, page.value)}
                        sx={{
                          my: 2,
                          color: '#3C3C3C',
                          display: 'block',
                          fontFamily: 'Montserrat',
                          fontWeight: 'bold',
                          ml: 3,
                          mr: 3,
                        }}
                      >
                        {page.label}
                      </Button>
                    </Link>
                  );
                }
              })}
            </Box>

            {/* Small devices menu icon */}
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => handleOpenNavMenu(event, 'menu')}
                color="inherit"
                sx={{ backgroundColor: 'white', ml: 11, borderRadius: '15px' }}
              >
                <MenuIcon sx={{ color: 'black', fontSize: '30px' }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
               
                {pages
                  .filter((page) => page.dropdown)
                  .map((page) => (
                    <MenuItem key={page.value}>
                      <Typography
                        variant="inherit"
                        sx={{ fontFamily: 'Montserrat', color: '#3C3C3C', fontWeight: 'bold' }}
                      >
                        {page.label}
                      </Typography>
                      <List>
                        {page.submenu &&
                          page.submenu.map((submenuItem) => (
                            <ListItem
                            sx={{ '&:hover': { backgroundColor: '' } }}
                              key={submenuItem.value}
                              onClick={() => handlePageChange(submenuItem.value, submenuItem.navigate, page.value)}
                            >
                              <ListItemText primary={submenuItem.label} sx={{color:'#3C3C3C',fontWeight:'500'}} />
                            </ListItem>
                          ))}
                      </List>
                    </MenuItem>
                  ))}

               
                {pages
                  .filter((page) => !page.dropdown)
                  .map((page) => (
                    <MenuItem key={page.value} onClick={() => handlePageChange(page.value, page.navigate, page.value)}>
                      <Typography variant="inherit" sx={{ fontFamily: 'Montserrat', color: '#3C3C3C', fontWeight: 'bold' }}>
                        {page.label}
                      </Typography>
                    </MenuItem>
                  ))}
              </Menu>
            </Box> */}

            <Box sx={{ flexGrow: 0 }}>
              <Button
                onClick={handleGetStartedClick}
                variant="contained"
                sx={{
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                  backgroundColor: '#2952A2',
                  borderRadius: '10px',
                  display: { xs: 'none', sm: 'none', md: 'block' },
                  '&:hover': {
                    backgroundColor: '#50A1CA',
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                Get Started for Free
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
