import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Faq from '../Faq/Faq'



export const Layout = () => {
    return (
        <>
            <Header/>

            <Box><Outlet /> </Box>
             <Faq />
            <Footer />

        </>
    )
}
