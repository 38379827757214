import {
  Button,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import background from "../../Assets/Images/features/Bg.webp";
import feature from "../../Assets/Images/features/feature3/1.webp";

const features = [
  {
    title: "Strategic Decision-Making",
    description:
      "Visualize member engagement, posts, transactions, and more. Take data-driven actions to enhance your community's experience.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
  {
    title: "Track Growth",
    description:
      "Monitor community health with easy-to-understand metrics. Compare trends, identify spikes, and strategize for consistent growth.",
    image: require("../../Assets/Images/features/feature1/3.png"),
  },
  {
    title: "Tailored Strategies",
    description:
      "Understand visitor sources, focus on specific communities, and filter by dates. Craft personalized approaches for maximum impact.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
];

const featureData = [
  {
    title: "Member Engagement on Display",
    description:
      "A vibrant community thrives on engagement. The Dashboard offers a bird's-eye view of member interactions, helping admins understand what's buzzing and what's not.",
    image: require("../../Assets/Images/features/feature3/2.webp"),
  },
  {
    title: "Numbers that Speak",
    description:
      "It's not just data; it's a story. Admins can assess the health of their community through numbers like total posts, members, and transactions. Instantly spot trends, understand growth, and make informed decisions.",
    image: require("../../Assets/Images/features/feature3/3.webp"),
  },
  {
    title: "Comparative Analysis",
    description:
      "See how your community is progressing. Compare current metrics with the previous month's stats. With increase or decrease percentages, you'll gauge your community's direction with clarity.",
    image: require("../../Assets/Images/features/feature3/4.webp"),
  },
  {
    title: "Visitor Insights",
    description:
      "Know your audience. The Dashboard lets you peek into the origins of your visitors. Understand where they're coming from and tailor your strategies accordingly.",
    image: require("../../Assets/Images/features/feature3/5.webp"),
  },
  {
    title: "Customization at Your Fingertips",
    description:
      "Filter the data to your liking. Focus on specific communities, narrow down by dates, and refine your insights.",
    image: require("../../Assets/Images/features/feature3/6.webp"),
  },
];

const Dashboard = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          overflow: "hidden",
          height: { xs: "75vh", md: "100vh" },
          mt:-12
        }}
      >
        {" "}
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color="#2952A2"
            fontSize={"36px"}
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              mt: 20,
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Access real-time insights with our
          </Typography>
          <Typography
            color="#3B9B7F"
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "30px", md: "36px" },
            }}
          >
            Dashboard
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Montserrat",
              textTransform: "capitalize",
              backgroundColor: "#000000",
              borderRadius: "10px",
              textAlign: "center",
              p: { xs: 1.5, md: 2 },
              mt: { xs: 10, md: 3 },
              ml: { xs: 0, md: 0 },
              display: { xs: "block", sm: "none", md: "none" },
              "&:hover": {
                backgroundColor: "#50A1CA", // Background color on hover
                cursor: "pointer",
                border: "none",
              },
            }}
          >
            Get Started for free
          </Button>
        </Stack>
      </Box>
      <Card
        elevation={0}
        sx={{ backgroundColor: "transparent", height: "fit-content", mt: -50 }}
      >
        <CardMedia
          component="img"
          alt="Background Image"
          src={feature}
          sx={{
            height: "100%",
            objectFit: "cover",
            position: "relative",
            mt: { xs: 35, md: 0 },
          }}
        />
      </Card>
      <Stack mt={10} sx={{ mt: { xs: 5, md: 10 } }}>
        <Typography
          sx={{
            color: "#2A53A2",
            fontSize: { xs: "25px", md: "30px" },
            fontFamily: "Montserrat",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
          }}
        >
          Empowering Decisions with Insights: Your Communn Dashboard
        </Typography>
      </Stack>

      {/* Features Start */}
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          ml: 4,
          mr: 4,
          pt: { xs: 5, md: 10 },
          pb: { xs: 5, md: 10 },
          mb: 5,
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} md={3.5} key={index}>
              <Card
                sx={{
                  pt: 7,
                  pb: 7,
                  pl: 1,
                  pr: 1,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "35%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "17px",
                    fontWeight: "bold",
                    p: 1,
                    textAlign: "center",
                    color: "#2952A2",
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#000000",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Features End */}

      {/* Features Data Start */}
      {featureData.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={{ xs: 2, md: 10 }}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: index % 2 === 0 ? "row" : "row-reverse",

                },
                
                paddingBottom: {
                  md: index % 2 === 0 ? "0px" : "100px",
                },


              }}
            >
              <Grid item xs={12} md={5.5} sx={{ m: { xs: 2, md: 0 } }}>
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4.5}>
                <Stack
                  sx={{
                    ml: 2,
                    pt: { xs: 0, md: 6 },
                    pb: { xs: 0, md: 6 },
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                  }}
                >
                  <Paper sx={{ p: 2, boxShadow: "none" }}>
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "18px", md: "24px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      ))}

      {/* Features Data End */}
    </>
  );
};

export default Dashboard;
