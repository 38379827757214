import React from 'react';
import { Stack, Grid, Card, Box, Typography } from '@mui/material';
import UseCaseSlider from './UseCaseSlider';

const HomeUseCases = () => {
  const cardData = [
    {
      imgSrc: require('../../Assets/Images/Home/2.png'),
      title: 'Homemaker',
      description: 'Transform your home into a global hub for homemakers to share recipes, home decor ideas, parenting tips, and more, cultivating a family-like, nurturing community.',
    },
    {
      imgSrc: require('../../Assets/Images/Home/3.png'),
      title: 'Entrepreneur',
      description: 'Supercharge your professional connections. Network with fellow entrepreneurs, freelancers, and experts. Share insights, collaborate, and unlock business opportunities.',
    },
    {
      imgSrc: require('../../Assets/Images/Home/4.png'),
      title: 'Gym Owner',
      description: 'Elevate your gym experience. Forge a tight-knit fitness community where members share workout routines, nutritional advice, and motivate each other. Build a strong fitness family.',
    },
  ];

  return (
    <Stack
      // display={"flex"}
      // flexDirection={"row"}
      // alignContent={"center"}
      // justifyContent={"center"}
      // alignItems={"center"}
      sx={{
        backgroundColor: "#FBFBFB",
        ml: { xs: 0, md: 10 },
        mr: { xs: 0, md: 10 },
        // pt: 10,
        // pb: 10,
        // pl: { xs: 5, md: 0 },
        // pr: { xs: 5, md: 0 },
        // mb: 5,
        borderRadius: "20px",
        pt:10,
        pb:10,
        pl:{xs:2, md:20},
        pr:{xs:2, md:20},
      }}
    >
       <UseCaseSlider />
    </Stack>
  );
};

export default HomeUseCases;
