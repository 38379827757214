import {
  Button,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import background from "../../Assets/Images/features/Bg.webp";
import feature from "../../Assets/Images/features/feature1/2.webp";

const features = [
  {
    title: "Effortless Member Management",
    description:
      "Our platform streamlines member organization and invites, letting you focus on community growth.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
  {
    title: "Total Control in Your Hands",
    description:
      "Assign roles, elevate members, and manage who enters your community.",
    image: require("../../Assets/Images/features/feature1/3.png"),
  },
  {
    title: "Foster Engagement and Identity",
    description:
      "Customize your community with member-defined roles and contributions.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
];

const featureData = [
  {
    title: "Filters that Unveil Clarity",
    description:
      "Picture this: You're an admin with multiple communities. With our smart filters, effortlessly navigate through your communities and find exactly what you need. No more hunting, just swift access.",
    image: require("../../Assets/Images/features/feature1/3.webp"),
  },
  {
    title: "Admin Power, Admin Choices",
    description:
      "Feel the empowerment as you wield the power to select communities you're an admin of, instantly generating tailored results. Manage and nurture your communities with precision, all at your fingertips.",
    image: require("../../Assets/Images/features/feature1/4.webp"),
  },
  {
    title: "The Magic of Invitations",
    description:
      "Be a community builder, inviting new members with a click. Watch your community flourish as you welcome like-minded individuals, creating an even stronger bond.",
    image: require("../../Assets/Images/features/feature1/5.webp"),
  },
  {
    title: "Empowerment in Member Management",
    description:
      "Become the conductor of your community orchestra. Elevate members, switch roles, and even observe their activity status. Control who stays and who thrives.",
    image: require("../../Assets/Images/features/feature1/6.webp"),
  },
  {
    title: "Unlock Your Private Realm",
    description:
      "For private communities, we're your gatekeepers. Approve requests, connect with potential members, and sculpt the perfect community for your vision.",
    image: require("../../Assets/Images/features/feature1/7.webp"),
  },
  {
    title: "Identity at a Glance",
    description:
      "Elevate your members' uniqueness! Assign roles like Gym Trainer, Fitness Enthusiast, and more. Let them shine and contribute effectively within your community.",
    image: require("../../Assets/Images/features/feature1/9.webp"),
  },
  {
    title:
      "Warn the ones who violates community guidelines to Ensure Community Harmony",
    description:
      "Foster a harmonious environment with a gentle warning system that encourages respect and cooperation.",
    image: require("../../Assets/Images/features/feature1/10.webp"),
  },
];

const MembersList = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          overflow: "hidden",
          height: { xs: "90vh", md: "100vh" },
          mt:-12,
        }}
      >
        {" "}
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color="#2952A2"
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              mt: 20,
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Simplify roles and permissions with
          </Typography>
          <Typography
            color="#3B9B7F"
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "30px", md: "36px" },
            }}
          >
            Member Management
          </Typography>

          <Button
            variant="contained"
            sx={{
              fontFamily: "Montserrat",
              textTransform: "capitalize",
              backgroundColor: "#000000",
              borderRadius: "10px",
              textAlign: "center",
              p: { xs: 1.5, md: 2 },
              mt: { xs: 10, md: 3 },
              ml: { xs: 0, md: 0 },
              display: { xs: "block", sm: "none", md: "none" },
              "&:hover": {
                backgroundColor: "#50A1CA", // Background color on hover
                cursor: "pointer",
                border: "none",
              },
            }}
          >
            Get Started for free
          </Button>
        </Stack>
      </Box>
      <Card
        elevation={0}
        sx={{ backgroundColor: "transparent", height: "fit-content", mt: -50 }}
      >
        <CardMedia
          component="img"
          alt="Background Image"
          src={feature}
          sx={{
            height: "100%",
            objectFit: "cover",
            position: "relative",
            mt: { xs: 35, md: 0 },
          }}
        />
      </Card>

      <Stack mt={10} sx={{ mt: { xs: 5, md: 0 } }}>
        <Typography
          sx={{
            color: "#2A53A2",
            fontSize: { xs: "25px", md: "30px" },
            fontFamily: "Montserrat",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
          }}
        >
          Join us on a journey where community management becomes a breeze! 🚀
        </Typography>
      </Stack>

      {/* Features Start */}
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          ml: 4,
          mr: 4,
          pt: { xs: 5, md: 10 },
          pb: { xs: 5, md: 10 },
          mb: 5,
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} md={3.5} key={index}>
              <Card
                sx={{
                  pt: 7,
                  pb: 7,
                  pl: 1,
                  pr: 1,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "35%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "17px",
                    fontWeight: "bold",
                    p: 1,
                    textAlign: "center",
                    color: "#2952A2",
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#000000",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Features End */}

      {/* Features Data Start */}
      {featureData.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={{ xs: 2, md: 10 }}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: index % 2 === 0 ? "row" : "row-reverse",

                },
                
                paddingBottom: {
                  md: index % 2 === 0 ? "0px" : "100px",
                },


              }}
            >
              <Grid item xs={12} md={5.5} sx={{ m: { xs: 2, md: 0 } }}>
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4.5}>
                <Stack
                  sx={{
                    ml: 2,
                    pt: { xs: 0, md: 6 },
                    pb: { xs: 0, md: 6 },
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                  }}
                >
                  <Paper sx={{ p: 2, boxShadow: "none" }}>
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "18px", md: "24px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      ))}
    </>
  );
};

export default MembersList;
