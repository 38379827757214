import {
  Button,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import background from "../../Assets/Images/features/Bg.webp";
import feature from "../../Assets/Images/features/feature3/1.webp";




const features = [
  {
    title: "Plan Personalization",
    description:
      "Craft plans that match your community's unique essence. Define costs, durations, and billing frequencies for plans that resonate with every member.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
  {
    title: "Membership Insights",
    description:
      "Track who's subscribed to each plan, ensuring you stay connected with your community's evolving needs.",
    image: require("../../Assets/Images/features/feature1/3.png"),
  },
  {
    title: "Flexibility Redefined",
    description:
      "Personalize plans to perfection. Offer exclusive discounts, flexible durations, and varied billing cycles for a subscription journey that suits all preferences.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
];



const featureData = [
  {
    title: "Diverse Subscription Models",
    description:
      "Communn provides flexible subscription options, including monthly, annual, and one-time plans.",
    image: require("../../Assets/Images/features/feature5/2.webp"),
  },
  {
    title: "Hassle-Free Payments",
    description:
      "Say goodbye to the headache of handling payments manually. Communn integrates with secure payment gateways, ensuring seamless and secure transactions for both you and your subscribers",
    image: require("../../Assets/Images/features/feature5/3.webp"),
  },
  {
    title: "Customizable Plans",
    description:
      "Tailor your subscription plans to meet your audience's needs. Set different price points, durations, and benefits to cater to a wide range of subscribers.",
    image: require("../../Assets/Images/features/feature5/4.webp"),
  },
  {
    title: "Automated Billing",
    description:
      "Forget about chasing down payments. Communn automates billing, ensuring your subscribers are charged accurately and on time. It's effortless revenue generation at your fingertips.",
    image: require("../../Assets/Images/features/feature5/5.webp"),
  },
  {
    title: "Membership Tiers",
    description:
      "Create multiple subscription tiers, each offering unique benefits. This encourages subscribers to choose a plan that aligns with their preferences, giving them a sense of exclusivity.",
    image: require("../../Assets/Images/features/feature5/6.webp"),
  },
  {
    title: "Subscriber Insights",
    description:
      "Gain valuable insights into your subscribers' preferences and behaviors. Understand what content or perks resonate most, allowing you to fine-tune your offerings for maximum engagement.",
    image: require("../../Assets/Images/features/feature5/7.webp"),
  },
  {
    title: "Notifications and Reminders",
    description:
      "Keep your subscribers informed and engaged with automated notifications and reminders. Notify them about upcoming payments, new content releases, or exclusive events.",
    image: require("../../Assets/Images/features/feature5/8.webp"),
  },
];

const Plans = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          overflow: "hidden",
          height: { xs: "90vh", md: "100vh" },
          mt:-12,
        }}
      >
        {" "}
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color="#2952A2"
            fontSize={"36px"}
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              mt: 20,
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Customize subscriptions with
          </Typography>
          <Typography
            color="#3B9B7F"
            fontSize={"36px"}
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Subscription Management
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Montserrat",
              textTransform: "capitalize",
              backgroundColor: "#000000",
              borderRadius: "10px",
              textAlign: "center",
              p: { xs: 1.5, md: 2 },
              mt: { xs: 10, md: 3 },
              ml: { xs: 0, md: 0 },
              display: { xs: "block", md: "none" },
              "&:hover": {
                backgroundColor: "#50A1CA", // Background color on hover
                cursor: "pointer",
                border: "none",
              },
            }}
          >
            Get Started for free
          </Button>
        </Stack>
      </Box>
      <Card
        elevation={0}
        sx={{ backgroundColor: "transparent", height: "fit-content", mt: -50 }}
      >
        <CardMedia
          component="img"
          alt="Background Image"
          src={feature}
          sx={{
            height: "100%",
            objectFit: "cover",
            position: "relative",
            mt: { xs: 35, md: 0 },
          }}
        />
      </Card>

      <Stack mt={10}>
        <Typography
          sx={{
            color: "#2A53A2",
            fontSize: { xs: "25px", md: "30px" },
            fontFamily: "Montserrat",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
          }}
        >
          Empowering Subscription Management: Tailor-Made Plans for Community
          Success
        </Typography>
      </Stack>






      {/* Features Start */}
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ ml: 4, mr: 4, pt: { xs: 5, md: 10 }, pb: { xs: 5, md: 10 }, mb: 5, borderRadius: "20px" }}
      >

        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} md={3.5} key={index}>
              <Card
                sx={{
                  pt: 7,
                  pb: 7,
                  pl: 1,
                  pr: 1,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "35%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "17px",
                    fontWeight: "bold",
                    p: 1,
                    textAlign: "center",
                    color: "#2952A2",
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#000000",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Features End */}








      {/* Features Data Start */}
      {featureData.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={{ xs: 2, md: 10 }}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: index % 2 === 0 ? "row" : "row-reverse",

                },
               
                paddingBottom: {
                  md: index % 2 === 0 ? "0px" : "100px",
                },


              }}
            >
              <Grid item xs={12} md={5.5} sx={{ m: { xs: 2, md: 0 } }}>
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4.5}>
                <Stack
                  sx={{
                    ml: 2,
                    pt: { xs: 0, md: 6 },
                    pb: { xs: 0, md: 6 },
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                  }}
                >
                  <Paper sx={{ p: 2, boxShadow: "none" }}>
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "18px", md: "20px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      ))}

      {/* Features Data Start */}
    </>
  );
};

export default Plans;
