import {
  Button,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import background from "../../Assets/Images/features/Bg.webp";
import feature from "../../Assets/Images/features/feature2/1.webp";



const features = [
  {
    title: "Transparent Financial Visibility",
    description:
      "Communn provides a detailed analysis of each transaction, providing valuable insights into your community's financial health.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
  {
    title: "Efficient Customization",
    description:
      "Customize your financial insights by filtering results based on specific communities or dates, providing focused data for better decision-making.",
    image: require("../../Assets/Images/features/feature1/3.png"),
  },
  {
    title: "Streamlined Balance Control",
    description:
      "Maintain financial stability by tracking balances and ensuring revenue allocation aligns with community needs, fostering growth and stability.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
];



const featureData = [
  {
    title: "Unveiling Financial Insights",
    description:
      "Step into a realm of transparency. Communn reveals the intricate details of every transaction. Dive into reports that dissect each payment, providing a complete understanding of your community's financial flow.",
    image: require("../../Assets/Images/features/feature2/2.webp"),
  },
  {
    title: " Tracking Revenue with Precision",
    description:
      "No revenue detail goes unnoticed. Witness the growth as you track total earnings, settled balances, and unsettled figures. Stay ahead of the financial curve with real-time data at your fingertips.",
    image: require("../../Assets/Images/features/feature2/3.webp"),
  },
  {
    title: "Balancing the Books",
    description:
      "Financial equilibrium is crucial. Communn's Reports section helps you maintain a balanced financial ecosystem. Allocate resources strategically, ensuring your community thrives.",
    image: require("../../Assets/Images/features/feature2/4.webp"),
  },
  {
    title: "Customization at Your Fingertips",
    description:
      "No two communities are alike, and Communn acknowledges that. Tailor your insights by filtering results based on selected communities or specific dates. This customization aids in making data-driven decisions.",
    image: require("../../Assets/Images/features/feature2/5.webp"),
  },
  {
    title: "Effortless Financial Communication",
    description:
      "Seamlessly request payments from members for community-related matters. Admins can even personalize requests, addressing unique needs while keeping financial records precise and hassle-free.",
    image: require("../../Assets/Images/features/feature2/6.webp"),
  },

];

const Payments = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          overflow: "hidden",
          height: { xs: "90vh", md: "100vh" },
          mt:-12,
        }}
      >
        {" "}
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color="#2952A2"
            fontSize={"36px"}
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              mt: 20,
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Handle transactions seamlessly with
          </Typography>

          <Typography
            color="#3B9B7F"
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Payment Management
          </Typography>

          <Button
            variant="contained"
            sx={{
              fontFamily: "Montserrat",
              textTransform: "capitalize",
              backgroundColor: "#000000",
              borderRadius: "10px",
              textAlign: "center",
              p: { xs: 1.5, md: 2 },
              mt: { xs: 10, md: 3 },
              ml: { xs: 0, md: 0 },
              display: { xs: "block", md: "none" },
              "&:hover": {
                backgroundColor: "#50A1CA", // Background color on hover
                cursor: "pointer",
                border: "none",
              },
            }}
          >
            Get Started for free
          </Button>
        </Stack>
      </Box>
      <Card
        elevation={0}
        sx={{ backgroundColor: "transparent", height: "fit-content", mt: -50 }}
      >
        <CardMedia
          component="img"
          alt="Background Image"
          src={feature}
          sx={{
            height: "100%",
            objectFit: "cover",
            position: "relative",
            mt: { xs: 35, md: 0 },
          }}
        />
      </Card>




      <Stack mt={10}>
        <Typography
          sx={{
            color: "#2A53A2",
            fontSize: { xs: "25px", md: "30px" },
            fontFamily: "Montserrat",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
          }}
        >
          Join us on a journey where community management becomes a breeze! 🚀
        </Typography>
      </Stack>





      {/* Features Start */}
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ ml: 4, mr: 4, pt: { xs: 5, md: 10 }, pb: { xs: 5, md: 10 }, mb: 5, borderRadius: "20px" }}
      >

        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} md={3.5} key={index}>
              <Card
                sx={{
                  pt: 7,
                  pb: 7,
                  pl: 1,
                  pr: 1,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "35%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "17px",
                    fontWeight: "bold",
                    p: 1,
                    textAlign: "center",
                    color: "#2952A2",
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#000000",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Features End */}




      {/* Features Data Start */}
      {featureData.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={{ xs: 2, md: 10 }}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: index % 2 === 0 ? "row" : "row-reverse",

                },
                
                paddingBottom: {
                  md: index % 2 === 0 ? "0px" : "100px",
                },


              }}
            >
              <Grid item xs={12} md={5.5} sx={{ m: { xs: 2, md: 0 } }}>
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4.5}>
                <Stack
                  sx={{
                    ml: 2,
                    pt: { xs: 0, md: 6 },
                    pb: { xs: 0, md: 6 },
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                  }}
                >
                  <Paper sx={{ p: 2, boxShadow: "none" }}>
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "18px", md: "24px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      ))}

      {/* Features Data Start */}
    </>
  );
};

export default Payments;
